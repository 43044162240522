<template>
  <v-container>
    <v-card-title>
      <v-spacer class="titulo-combi-search">DETALLE</v-spacer>
      <v-text-field
        v-if="desserts"
        style="max-width: 500px; width: 100%"
        v-model="search"
        append-icon="search"
        label="Buscar..."
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-toolbar flat color="white">
      <v-toolbar-title></v-toolbar-title>
      <!-- <v-switch
              v-model="filtrar_lineas"
              label="Solo pendientes"
              color="primary"
              hide-details
              @change="setlineas()"
             ></v-switch> -->
      <v-spacer></v-spacer>
      <v-dialog v-model="dialog" persistent max-width="500px">
        <template v-if="permitirreservar" v-slot:activator="{ on }">
          <v-btn color="primary" dark class="mb-2" v-on="on">{{
            $t("lineas_dialog_btn_añadir")
          }}</v-btn>
        </template>
        <v-card style="background: #ffffff">
          <v-card-title>
            <span class="headline">Añadir Servicio</span>
          </v-card-title>
          <v-card-text>
            <v-container v-if="dialog_view == 'search'">
              <v-text-field
                v-model="articulo_desc"
                label="Buscar por referencia o descripción..."
              ></v-text-field>
              <v-list class="resultado_busqueda" v-if="results_articulo">
                <v-list-item
                  v-for="item in results_articulo"
                  :key="item.r + item.n"
                  @click="selecionar_articulo(item)"
                >
                  <v-list-item-content>
                    <strong style="font-size: 11px">{{ item.r }}</strong>
                    <v-list-item-title v-html="item.d"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-container>
            <v-container v-if="dialog_view == 'insert'">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="lineas.referencia"
                    label="Referencia"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    class="uppercase"
                    v-model="lineas.descripcion"
                    label="Descripción"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="lineas.canprevista"
                    align-left
                    type="number"
                    label="Cantidad"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#555555" text @click="closedialog()">Cancelar</v-btn>
            <v-btn
              color="#555555"
              text
              @click="dialog_view = 'search'"
              v-if="dialog_view == 'insert'"
              >Buscar</v-btn
            >
            <v-btn
              color="#555555"
              text
              @click="insert(lineas)"
              v-if="dialog_view == 'insert'"
              >{{ $t("carro_dialog_titulo_btn_linea") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <v-data-table
      v-if="lineas_mostrar && lineas_mostrar.length > 0"
      :headers="headers"
      :items="lineas_mostrar"
      item-key="idlinea"
      :search="search"
      :footer-props="{ 'items-per-page-options': [100, 1000] }"
      sort-by="nl"
      class="tabla_escritorio pr-3 pl-3"
    >
      <template v-slot:item="{ item }">
        <tr :key="item.idlinea">
          <td class="text-xs-left col-descripcion">{{ item.r }}</td>
          <td class="text-xs-left col-descripcion">{{ item.d }}</td>
          <!-- <td class="text-center col-descripcion">{{ item.c }}</td> -->

          <td class="text-xs-left col-descripcion">
            <div class="box-cantidad" style="margin: auto">
              <v-icon
                @click="btnRestCant(item)"
                style="border-right: none"
                v-if="orden.editable == 't'"
                >remove</v-icon
              >
              <input
                v-if="orden.editable == 't'"
                v-model="item.c"
                @change="actualizarlinea(item)"
                min="0"
                step="0.5"
                type="number"
                value=""
              />
              <v-icon
                @click="btnSumCant(item)"
                style="border-left: none"
                v-if="orden.editable == 't'"
                >add</v-icon
              >
              <label v-if="orden.editable == 'f'">{{ item.c }}</label>
            </div>
          </td>

          <td class="text-xs-left col-descripcion">{{ item.pvpunitario }}</td>
          <td class="text-xs-left col-descripcion">{{ item.pvpsindto }}</td>
          <!-- <td class="text-xs-left col-descripcion">{{ item.pvptotal }}</td> -->

          <td class="justify-center text-center">
            <!-- <v-btn icon="icon" @click="dialogdelete.active = true;dialogdelete.item=item" v-if="orden.editable=='t'"> -->
            <v-btn
              icon="icon"
              @click="btnDelete(item)"
              v-if="orden.editable == 't'"
            >
              <v-icon class="grey--text">delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template slot="no-data">
        <v-alert :value="true" color="white">{{ $t("carro_vacio") }}</v-alert>
      </template>

      <!-- <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
          <v-icon small @click="deleteItem(item)">delete</v-icon>
        </template> -->
      <!-- <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template> -->
    </v-data-table>

    <v-container v-else>
      <v-card>
        <v-card-text> NO HAY NINGUNA LINEA </v-card-text>
      </v-card>
    </v-container>

    <v-select
      v-if="lineas_mostrar && lineas_mostrar.length > 0"
      class="tabla_lineas_movil"
      :item="filters"
      item-value="value"
      v-bind:label="filter === '' ? filters[0].text : ''"
      v-model="filter"
      style="background-color: white !important"
    ></v-select>
    <!--  <v-data-table
        v-if="lineas_mostrar && lineas_mostrar.length > 0"
        :headers="filters"
        :items="lineas_mostrar"
        item-key="idlinea"
        :search="search"
        :footer-props="{'items-per-page-options': [100,1000]}"
        sort-by="nl"
        class="tabla_lineas_movil"
      > -->

    <v-container
      v-if="prealbaranesfilter && lineas_mostrar && lineas_mostrar.length > 0"
      class="tabla_lineas_movil"
      grid-list-md
    >
      <v-layout
        row
        wrap
        v-for="item in prealbaranesfilter"
        :key="'movil_' + item.r + item.n"
      >
        <v-flex>
          <v-row>
            <v-chip
              class="mb-3 mr-3"
              label
              v-if="item.r"
              color="#666666"
              text-color="white"
            >
              <v-icon left>label</v-icon>{{ item.r }}
            </v-chip>
            <label class="prev_movil"> {{ item.d }}</label
            ><br />

            <!-- <v-chip class="mb-3 mr-3" label v-if="item.c || item.c || item.c"> 
                      <v-icon left>pan_tool</v-icon>
                      <label>
                       Prev:<b>{{item.c}}</b>&nbsp;
                       Res:<b>{{item.c}}</b>&nbsp;
                       Serv:<b>{{item.c}}</b>
                       </label>
                    </v-chip> 
                    <v-chip class="mb-3 mr-3" label v-if="item.disp || item.resprov"> 
                      <v-icon left>shopping_cart</v-icon>
                      <label>
                      <label>Disp:<b>{{item.disp}}</b></label>&nbsp;
                      <label v-if="item.resprov">Res prov:<b>{{item.resprov}}</b></label>
                      </label>
                       
                    </v-chip>  -->

            <div class="line" style="width: 100%">
              <div class="flex-content">
                <!-- <label class="prev_movil" > Cantidad {{ item.c }} </label><br> -->
                <label class="prev_movil">
                  Precio unidad {{ item.pvpunitario }} </label
                ><br />
                <label class="prev_movil"> Precio {{ item.pvpsindto }} </label
                ><br />
                <!--             <label class="prev_movil" > Precio total {{ item.pvptotal }} </label>
 -->
                <div class="box-cantidad" style="margin: 5px 10px 5px 5px">
                  <v-icon
                    @click="btnRestCant(item)"
                    style="border-right: none"
                    v-if="orden.editable == 't'"
                    >remove</v-icon
                  >
                  <input
                    v-if="orden.editable == 't'"
                    v-model="item.c"
                    style="width: 120px;"
                    @change="actualizarlinea(item)"
                    min="0"
                    step="0.5"
                    type="number"
                    value=""
                  />
                  
                  <v-icon
                    @click="btnSumCant(item)"
                    style="border-left: none"
                    v-if="orden.editable == 't'"
                    >add</v-icon
                  >
                  <label v-if="orden.editable == 'f'">{{ item.c }}</label>
                   <v-btn
                      icon="icon"
                      style="float:right;"
                      @click="btnDelete(item)"
                      v-if="orden.editable == 't'"
                    >
                <v-icon class="grey--text">delete</v-icon>
              </v-btn>
                </div>
              </div>
             

              <!-- <v-dialog v-if="confirmer.active" :value="confirmer.active" max-width="290">
              <v-card>
                <v-card-title class="headline">{{
                  confirmer.title
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click.native="borrararticulo(item)"
                    >Confirm</v-btn
                  >
                  <v-btn color="blue darken-1" text @click.native="confirmer.active = false"
                    >Cancel</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>  -->
            </div>
          </v-row>
          <v-row>
            <!--  <div class="text">         
                      <div v-if="item.d">
                        {{item.d}}
                      </div>
                    </div> -->
          </v-row>

          <v-divider style="margin: 8px; width: auto"></v-divider>
        </v-flex>
      </v-layout>
    </v-container>
  </v-container>
</template>

<script>
import { gw } from "@/gw.js";
//import axios from 'axios'

export default {
  mixins: [gw],
  name: "lineasServicios",
  props: ["desserts", "orden"],
  data: () => ({
    items_actualizarlinea: [],
    search: "",
    filtrar_lineas: true,
    _desserts: null,
    lineas_mostrar: [],
    permitirreservar: true,
    dialog: false,
    headers: [
      { text: "Ref.", value: "r" },
      { text: "Descripción", value: "d" },
      { text: "Cantidad", value: "c" },
      { text: "Precio unidad", value: "pvpunitario" },
      { text: "Precio", value: "pvpsindto" },
      { text: "", value: "" },

      //{ text: "Precio total", value: "pvptotal" }
    ],
    //desserts: [],
    articulo_desc: "",
    articulo_desc_anterior: "",
    results_articulo: [],
    timeout: null,
    dialog_view: "insert",
    lineas: {
      canreservada: "0",
      canservida: "0",
      canprevista: "1",
      descripcion: "",
      codorden: "",
      referencia: "",
      observaciones: " ",
    },
    filters: [
      { text: "Referencia", value: "r" },
      { text: "Descripción", value: "d" },
      { text: "Cantidad", value: "c" },
      { text: "Precio unidad", value: "pvpunitario" },
      { text: "Precio", value: "pvpsindto" },
      { text: "Precio total", value: "pvptotal" },
    ],
    filter: "",
  }),
  computed: {
    prealbaranesfilter() {
      let _this = this;
      if (
        this._desserts != null &&
        this._desserts.length > 0 &&
        this._desserts[0]["search"] == undefined
      ) {
        this.lineas_mostrar.forEach(function (element) {
          element["search"] = Object.values(element).toString().toLowerCase();
        });
      }
      if (this.search.length > 2) {
        return this.lineas_mostrar.filter((o) => {
          if (o["search"].search(this.search.toLowerCase()) != -1) {
            this.closekeyboard();
            return this.lineas_mostrar;
          }
        });
      } else {
        this.closekeyboard();
        return this.lineas_mostrar;
      }
    },
  },
  mounted() {
    /*this._desserts = this.desserts;
    if(this._desserts!=null && this._desserts.length>0 ){
      this._desserts.forEach(e => {
        if(e.cs < e.cp){
          this.lineas_mostrar.push(e);
        }
      });
      if(this.lineas_mostrar.length == 0){
          this.lineas_mostrar = this._desserts;
          this.filtrar_lineas = false;
      }
    }*/
  },
  watch: {
    articulo_desc(val) {
      //if (val.length >= 3 &&
      //(this.results_articulo.length==100 || this.results_articulo.length==0)
      //|| this.articulo_desc.length < this.articulo_desc_anterior.length) {

      if (val.length >= 3) {
        this.articulo_desc_anterior = val;
        var _this = this;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          _this.buscararticulo(val);
        }, 500);
      } else if (val.length < 3) {
        this.results_articulo = [];
      }
      this.articulo_desc = val;
    },
    desserts() {
      this._desserts = this.desserts;
      if (this._desserts != null && this._desserts.length > 0) {
        this._desserts.forEach((e) => {
          if (e.cs < e.cp) {
            this.lineas_mostrar.push(e);
          }
        });
        if (this.lineas_mostrar.length == 0) {
          this.lineas_mostrar = this._desserts;
          this.filtrar_lineas = false;
        }
      }
    },
    filter(val) {
      this.filter = val;
      this.filterlist();
    },
    /*'lineas.descripcion': function (val) {
      this.lineas.descripcion = val.toUpperCase();
    },*/
  },
  methods: {
    filterlist() {
      if (this._desserts != undefined) {
        let canpo = this.filter;
        return this.lineas_mostrar.sort((a, b) =>
          b[canpo] > a[canpo] ? -1 : 1
        );
      }
    },
    setlineas() {
      this.lineas_mostrar = this._desserts;
      /*if(this.filtrar_lineas === true){
        this.lineas_mostrar = [];
        if(this._desserts!=null && this._desserts.length>0){
          this._desserts.forEach(e => {
            if(e.cs < e.cp){
              this.lineas_mostrar.push(e);
            }
          });
        }
        //if(this.lineas_mostrar.length == 0){
        //  this.lineas_mostrar = this._desserts;
        //  this.filtrar_lineas = false;
        //} 
      }else if(this.filtrar_lineas === false){
        this.lineas_mostrar = this._desserts;
      }*/
    },
    closedialog() {
      this.dialog = false;
      this.articulo_desc = "";
      this.articulo_desc_anterior = "";
      this.results_articulo = [];
      this.dialog_view = "insert";
      this.lineas.cantidad = "1";
      this.lineas.descripcion = "";
      this.lineas.idprealbaran = "";
      this.lineas.referencia = "";
      this.lineas.canreservada = "0";
      this.lineas.canservida = "0";
      this.lineas.canprevista = "1";
      this.lineas.codorden = "";
      this.lineas.observaciones = "";
    },
    insert(articulo) {
      this.lineas.referencia =
        articulo.r === undefined ? this.lineas.referencia : articulo.r;
      this.lineas.descripcion =
        articulo.d === undefined
          ? this.lineas.descripcion.toUpperCase()
          : articulo.d.toUpperCase();
      this.lineas.observaciones =
        articulo.obs === undefined
          ? this.lineas.observaciones.toUpperCase()
          : articulo.obs.toUpperCase();
      this.lineas.codorden = this._routerRoot._route.params.codorden;

      //validaciones
      if (this.lineas.referencia == "" || this.lineas.referencia == null) {
        return false;
      }

      var data = {};
      data["coduser"] = this.auth.coduser;
      data["codcliente"] = this.auth.coduser;
      data["observaciones"] = this.lineas.observaciones.toUpperCase();
      data["canprevista"] = this.lineas.canprevista;
      data["canservida"] = this.lineas.canservida;
      data["canreservada"] = this.lineas.canreservada;
      data["referencia"] = this.lineas.referencia;
      data["descripcion"] = this.lineas.descripcion.toUpperCase();
      data["codorden"] = this.lineas.codorden;
      this.closedialog();
      let _this = this;
      this.api("/peticion/lineasservicios/insert", data, true).then(
        (_resp) => {
          _this._desserts = _resp.datos;
          _this.setlineas();
          this.toasted("Insertado correctamente", "success", "check");
          return true;
        },
        (err) => {
          console.log(err);
          return false;
        }
      );
    },
    selecionar_articulo(articulo) {
      this.lineas.referencia =
        articulo.r === undefined ? this.lineas.referencia : articulo.r;
      this.lineas.descripcion =
        articulo.d === undefined
          ? this.lineas.descripcion.toUpperCase()
          : articulo.d.toUpperCase();
      this.lineas.observaciones =
        articulo.obs === undefined
          ? this.lineas.observaciones.toUpperCase()
          : articulo.obs.toUpperCase();
      this.lineas.codorden = this._routerRoot._route.params.codorden;
      this.articulo_desc = "";
      this.articulo_desc_anterior = "";
      this.results_articulo = [];
      this.dialog_view = "insert";
    },
    buscararticulo(search) {
      let _this = this;
      let data = {};
      //if(kind == 'descripcion') data['descripcion'] = search;
      data["busqueda"] = search;

      this.api("/peticion/articulosfiltro/", data, false).then(
        (resp) => {
          if (resp.success == 1 && resp.datos != null) {
            //Todo Ok
            _this.results_articulo = resp.datos;
          }
        },
        (err) => {
          //this.vista = "pedidoerror";
          console.log(err);
        }
      );
    },
    btnSumCant(item) {
      item.c = parseFloat(item.c) + 1;
      this.actualizarlinea_botoncant(item);
    },
    btnRestCant(item) {
      //if (item.cantidad > 1) {
      item.c = parseFloat(item.c) - 1;
      this.actualizarlinea_botoncant(item);
      //}
    },
    actualizarlinea_botoncant(item) {
      let _this = this;
      this.items_actualizarlinea = item;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        _this.actualizarlinea(_this.items_actualizarlinea);
      }, 800);
    },

    actualizarlinea(items) {
      let _this = this;
      let data = {};
      data["coduser"] = this.auth.coduser;
      data["codcliente"] = this.auth.coduser;
      data["codorden"] = this._routerRoot._route.params.codorden;
      /*       data["idlinea"] = items.n;
      data["cantidad"] = items.c; */
      data["items"] = items;
      this.api("/peticion/lineasservicios/update", data, true).then(
        (resp) => {
          if (resp.success == 1 && resp.datos != null) {
            //Todo Ok
            _this._desserts = resp.datos;
            _this.setlineas();
            this.toasted("Guardado correctamente", "success", "check");
          }
        },
        (err) => {
          //this.vista = "pedidoerror";
          console.log(err);
        }
      );
    },

    btnDelete(items) {
      let _this = this;
      let data = {};
      data["coduser"] = this.auth.coduser;
      data["codcliente"] = this.auth.coduser;
      data["codorden"] = this._routerRoot._route.params.codorden;
      data["items"] = items;
      this.api("/peticion/lineasservicios/eliminar", data, true).then(
        (resp) => {
          if (resp.success == 1 && resp.datos != null) {
            //Todo Ok
            _this._desserts = resp.datos;
            _this.setlineas();
            this.toasted("Eliminado correctamente", "success", "check");
          }
        },
        (err) => {
          //this.vista = "pedidoerror";
          console.log(err);
        }
      );
    },

    // editItem(item) {
    //   this.editedIndex = this._desserts.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.dialog = true;
    // },

    // deleteItem(item) {
    //   //const index = this._desserts.indexOf(item);
    //   //confirm("Are you sure you want to delete this item?") && this._desserts.splice(index, 1);
    // },

    // close() {
    //   this.dialog = false;
    //   setTimeout(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem);
    //     this.editedIndex = -1;
    //   }, 300);
    // },

    // save() {
    //   if (this.editedIndex > -1) {
    //     Object.assign(this._desserts[this.editedIndex], this.editedItem);
    //   } else {
    //     this._desserts.push(this.editedItem);
    //   }
    //   this.close();
    // }
  },
};
</script>
