<template>
    <v-container class="view-ordentrabajo" grid-list-md>
        <v-layout row wrap>
            <v-flex xs12>
                <v-chip label color="#4fa272" text-color="white" class="ml-0 mr-3 mb-3">
                    <v-icon left>label</v-icon>{{orden.codorden}}
                </v-chip>
                
                <div>
                    <!-- <v-tabs center-active >
                        <v-tab
                        v-for="item in tabs"
                        :key="item.title"
                        @click="setTabs(item.id)"
                        style="padding-right: 25px;">
                        <v-icon style="padding-right: 7px;">{{item.icon}}</v-icon> <span class="escritorio" >{{item.title}}</span>     <span class="movil" >{{item.title_movil}}</span>
                        </v-tab>
                    </v-tabs> -->

                        <Info v-if="bottomNav.itemactive=='info'" v-bind:desserts="orden"/>
                        <lineasServicios v-else-if="bottomNav.itemactive=='lineasservicios'" v-bind:desserts="lineasservicios" v-bind:orden="orden"/>

                        <!-- <Albaranes v-else-if="bottomNav.itemactive=='albaranes'" v-bind:desserts="albaranes"/> -->
                        <!-- <PreAlbaranes v-else-if="bottomNav.itemactive=='prealbaranes'" v-bind:desserts="prealbaranes"/> -->
                        <!-- <MaterialReservado v-else-if="bottomNav.itemactive=='material'" v-bind:desserts="material"/> -->
                        <!-- <Proyectos v-if="tabactive=='proyecto'" v-bind:desserts="proyectos" /> -->
                </div>

            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { gw } from '@/gw.js'
import Info from '@/components/ordentrabajo/Info'
import lineasServicios from '@/components/ordentrabajo/lineasServicios'
//import MaterialReservado from '@/components/ordentrabajo/MaterialReservado'
//import PreAlbaranes from '@/components/ordentrabajo/PreAlbaranes'
//import Albaranes from "@/components/ordentrabajo/Albaranes";
// import Proyectos from '@/components/ordentrabajo/Proyectos';

export default {
    mixins: [gw],
    name: 'OrdenTrabajo',
    components: {
        //MaterialReservado,
        //PreAlbaranes,
        //Albaranes,
        Info,
        lineasServicios
        // Proyectos
    }, 
    data: () => ({
        orden:{},
        lineasservicios:[],
        detalle:[],
        prealbaranes:[],
        albaranes:[],
        //proyectos:[],
        //tabactive: 'info',
        /* tabs: [
            { id: "prealbaranes", title_movil:"PreA", title: "Prealbaranes", icon: "filter_1" },
            { id: "albaranes", title_movil:"Alb", title: 'Albaranes', icon:'spellcheck'},
            { id: "material", title_movil:"Mat", title: "Material R.", icon: "pan_tool" }
            // { id: "proyecto", title_movil:"proy", title: "Proyecto", icon: "assignment" },
        ], */
    }), 
    mounted() {
        this.data();
        this.setbottomNav({
            itemactive: 'info', //Default el primero
            disabled: false,
            items: [{
                id: 'info',
                text: 'Info',
                icon: 'info',
                disabled: false,
                view: 'ordentrabajo'
            },
            {
                id: 'lineasservicios',
                text: 'Detalle',
                icon: 'list',
                disabled: false,
                view: 'ordentrabajo'
            }]
            //views: ['ordentrabajo','prealbaran','albaran']
         });
    },
    watch: {
        'bottomNav.itemactive': function (val) {
            this.data(val);
        }
    },
    methods: {
        /* setTabs(active){
            this.setbottomNav(this.tabactive=active);
        }, */
        data(action="todo"){
            let _this = this;
        let post ={'codorden': this._routerRoot._route.params.codorden,'action':action}; //responsedata: todo, orden o info, prealbaranes, albaranes, material
        this.api("/peticion/ordentrabajo/getorden", post).then(_return => {
            
            if(_return['success']=="1"){
                if(action=="info" || action=="orden" || action=="todo") this.orden = _return['datos']['orden'];
                if(action=="material" || action=="todo") this.material = _return['datos']['material'];
                if(action=="prealbaranes" || action=="todo") this.prealbaranes = _return['datos']['prealbaranes'];
                if(action=="albaranes" || action=="todo") this.albaranes = _return['datos']['albaranes'];
                if(action=="lineasservicios" || action=="todo") this.lineasservicios = _return['datos']['lineasservicios'];
            }
            else if (_return['success']=="3"){//no existe parte
                this.toasted("Este parte está cerrado o no existe", "error", "error");
                this.to('/ordentrabajo','rerender');
            }
            else{
                this.orden = [];
            }
        });
        this.setBreadcrumbs([
            {
              text: 'Partes de servicios',
              disabled: false,
              to: '/ordenestrabajo',
            },
            {
              text: this._routerRoot._route.params.codorden,
              disabled: true,
              to: '',
            }
          ]);
        }
    },
    /*computed: {
        filteredList() {
             return this.ordenes.filter(o => {
                if(this.ordenes.length < 100 || this.search.length>2){
                    if(o['search'].search(this.search.toLowerCase()) != -1){
                        return this.ordenes;
                    }
                }
                else{
                    return this.ordenes;
                }
            })
        }
    }*/
}
</script>